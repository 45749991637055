<template>
  <div>
    <base-button @click="handleClick">修改邮箱</base-button>
    <el-dialog
      class="dialog"
      title="修改邮箱"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
    >
      <el-form :model="form" :rules="rules" :disabled="loading" ref="form">
        <el-form-item label="密码" prop="password">
          <el-input type="password" autocomplete="current-password" v-model="form.password" show-password />
        </el-form-item>
        <el-form-item label="新邮箱" prop="newEmail">
          <el-input type="email" v-model="form.newEmail" />
        </el-form-item>
        <el-form-item label="验证码" prop="verifyCode">
          <BaseInputVerifyCode v-model="form.verifyCode" :email="form.newEmail" />
        </el-form-item>
      </el-form>
      <template v-slot:footer>
        <base-row>
          <base-button
            :disable="loading"
            :loading="loading"
            @click="handleConfirm"
            class="confirm-button"
            type="primary"
          >
            {{ confirmLabel }}
          </base-button>
        </base-row>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import BaseInputVerifyCode from '@/components/BaseInputVerifyCode'
import rule from '@/validate/rule'
import { dealOtherError } from '@/api/error'
import { APICode } from '@/api/common'
import api from '@/api'
import validate from '@/validate/validate'

export default {
  components: {
    BaseInputVerifyCode
  },
  data () {
    return {
      dialogVisible: false,
      loading: false,
      validatorCallback: {
        password: null,
        verifyCode: null
      },
      form: {
        password: '',
        newEmail: '',
        verifyCode: ''
      },
      rules: {
        password: [
          {
            validator: (rule, value, callback) => {
              if (value === '') {
                callback(new Error('请输入密码'))
              }
              callback()
            }
          },
          {
            validator: (rule, value, callback) => {
              this.validatorCallback.password = callback
            }
          }
        ],
        verifyCode: rule.verifyCode.concat([
          {
            validator: (rule, value, callback) => {
              this.validatorCallback.verifyCode = callback
            }
          }
        ]),
        newEmail: rule.email
      }
    }
  },
  methods: {
    handleClick () {
      this.dialogVisible = true
    },
    handleConfirm () {
      this.$refs.form.validateField(['password', 'verifyCode'], () => {})
      this.$refs.form.validateField('newEmail', errorMessage => {
        if (errorMessage ||
          this.form.password === '' ||
          validate.verifyCode(this.form.verifyCode)) return
        this.changeEmail()
      })
    },
    changeEmail () {
      this.loading = true
      setTimeout(async () => {
        try {
          await api.changeEmail(this.form.password,
            this.form.newEmail, this.form.verifyCode)
          this.$emit('updated')
          this.dialogVisible = false
          setTimeout(() => {
            this.$message.success('修改邮箱成功！')
            this.clearForm()
            this.loading = false
          }, 300)
        } catch (error) {
          this.loading = false
          if (error.resp) {
            const resp = error.resp
            if (resp.code === APICode.ValidateFailed ||
              resp.code === APICode.ParamFormatError) {
              this.validatorCallback.password(new Error('密码错误，请重新输入'))
              return
            }
            if (resp.code === APICode.BadRequest) {
              this.validatorCallback.verifyCode(new Error('验证码错误，请重新输入'))
              return
            }
          }
          this.dialogVisible = false
          setTimeout(() => {
            dealOtherError(this, error)
            this.clearForm()
          }, 300)
        }
      }, 500)
    },
    handleClose (done) {
      done()
      this.clearForm()
    },
    clearForm () {
      this.$refs.form.resetFields()
    }
  },
  computed: {
    confirmLabel () {
      return this.loading ? '修改中' : '确认修改'
    }
  }
}
</script>

<style scoped>
.confirm-button {
  flex-grow: 1;
}
.dialog >>> .el-dialog__body {
  padding-bottom: 0;
}
</style>
