<template>
  <div>
    <el-upload
      v-loading="loading"
      :disabled="loading"
      action=""
      :http-request="uploadAvatar"
      :show-file-list="false">
      <div class="tips"> 修改头像 </div>
      <el-avatar :key="userImg" :size="60" :src="userImg" icon="el-icon-user-solid" />
    </el-upload>
  </div>
</template>

<script>
import api from '@/api'
import { dealOtherError } from '@/api/error'
import validate from '@/validate/validate'

export default {
  props: {
    userImg: String
  },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    /**
     * UploadRequest
     * @typedef {Object} UploadRequest
     * @property {File} file
     */

    /**
    * uploadAvatar
    * @param {UploadRequest} request
    * @return {Promise<void>}
    */
    async uploadAvatar ({ file }) {
      const error = validate.avatarFile(file)
      if (error) {
        this.$message.error(error.message)
        return
      }
      this.loading = true
      try {
        const { data: { link } } = await api.uploadAvatar(file)
        setTimeout(() => {
          this.loading = false
          this.$emit('updated', link)
        }, 1000)
      } catch (error) {
        dealOtherError(this, error)
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.tips {
  display: flex;
  position: absolute;
  color: white;
  background-color: rgba(0, 0, 0, .5);
  font-size: 0.8em;
  z-index: 1;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  justify-content: space-around;
  flex-direction: column;
  opacity: 0;
  transition: opacity .3s;
}

.el-upload:hover .tips {
  opacity: unset;
}
</style>
