<template>
  <BaseAside :menu="menu" :default-path="defaultPath" />
</template>

<script>
import BaseAside from '@/components/BaseAside'

export default {
  components: {
    BaseAside
  },
  mounted () {
    const len = this.$route.fullPath.length
    if (this.$route.fullPath[len - 1] === '/') {
      this.defaultPath = this.$route.fullPath.substring(0, len - 1)
    } else {
      this.defaultPath = this.$route.fullPath
    }
  },
  data () {
    return {
      defaultPath: '',
      menu: [
        {
          path: '/profile',
          name: 'home',
          label: '个人信息',
          icon: 'el-icon-user'
        },
        {
          path: '/profile/about',
          name: 'about',
          label: '关于',
          icon: 'el-icon-info'
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>
