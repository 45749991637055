<template>
  <div>
    <base-button @click="handleClick">修改密码</base-button>
    <el-dialog
      class="dialog"
      title="修改密码"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
    >
      <el-form :disabled="loading" ref="form" :model="form" :rules="rules">
        <el-form-item label="旧密码" prop="oldPassword">
          <el-input type="password" autocomplete="current-password" v-model="form.oldPassword" show-password />
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword">
          <el-input type="password" autocomplete="new-password" v-model="form.newPassword" show-password />
        </el-form-item>
        <el-form-item label="确认密码" prop="confirmPassword">
          <el-input v-model="form.confirmPassword" show-password />
        </el-form-item>
      </el-form>
      <template v-slot:footer>
        <base-row>
          <base-button
            :disable="loading"
            :loading="loading"
            @click="handleConfirm"
            class="confirm-button"
            type="primary"
          >
            {{ confirmLabel }}
          </base-button>
        </base-row>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import rule from '@/validate/rule'
import { APICode } from '@/api/common'
import { dealOtherError } from '@/api/error'
import api from '@/api'

export default {
  data () {
    return {
      dialogVisible: false,
      loading: false,
      validatorCallback: null,
      form: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      },
      rules: {
        oldPassword: [
          {
            validator: (rule, value, callback) => {
              if (value === '') {
                callback(new Error('请输入密码'))
              }
              callback()
            }
          },
          {
            validator: (rule, value, callback) => {
              this.validatorCallback = callback
            }
          }
        ],
        newPassword: rule.password.concat([
          {
            validator: (rule, value, callback) => {
              if (value === this.form.oldPassword) {
                callback(new Error('与旧密码一致'))
                return
              }
              callback()
            }
          }
        ]),
        confirmPassword: [
          {
            validator: (rule, value, callback) => {
              if (value === '') {
                callback(new Error('请再次输入密码'))
                return
              }
              if (value !== this.form.newPassword) {
                callback(new Error('两次输入密码不一致'))
                return
              }
              callback()
            }
          }
        ]
      }
    }
  },
  methods: {
    handleClick () {
      this.dialogVisible = true
    },
    handleConfirm () {
      let valid = true
      let count = 0
      this.$refs.form.validateField('oldPassword', () => {})
      this.$refs.form.validateField(
        ['newPassword', 'confirmPassword'], errorMessage => {
          if (errorMessage) {
            valid = false
            return
          }
          if (valid && ++count === 2 && this.form.oldPassword !== '') {
            this.changePassword()
          }
        })
    },
    changePassword () {
      this.loading = true
      setTimeout(async () => {
        try {
          await api.changePassword(this.form.oldPassword, this.form.newPassword)
          this.dialogVisible = false
          setTimeout(() => {
            this.$message.success('修改密码成功！')
            this.clearForm()
            this.loading = false
          }, 300)
        } catch (error) {
          this.loading = false
          if (error.resp) {
            const resp = error.resp
            if (resp.code === APICode.ValidateFailed ||
              resp.code === APICode.ParamFormatError) {
              this.validatorCallback(new Error('密码错误，请重新输入'))
              return
            }
          }
          this.dialogVisible = false
          setTimeout(() => {
            dealOtherError(this, error)
            this.clearForm()
          }, 300)
        }
      }, 500)
    },
    handleClose (done) {
      done()
      this.clearForm()
    },
    clearForm () {
      this.$refs.form.resetFields()
    }
  },
  computed: {
    confirmLabel () {
      return this.loading ? '修改中' : '确认修改'
    }
  }
}
</script>

<style scoped>
.confirm-button {
  flex-grow: 1;
}
.dialog >>> .el-dialog__body {
  padding-bottom: 0;
}
</style>
