<template>
  <el-container class="self-info-main">
    <el-header style="display: flex">
      <base-row justify="start">
        <span> 个人信息 </span>
      </base-row>
    </el-header>
    <el-main style="padding-top: 0">
      <el-card v-loading="loading" class="card">
        <BaseRowWithLabel label="头像">
          <TheAvatarUpload :user-img="userImg" @updated="handleUpdateAvatar" />
        </BaseRowWithLabel>
        <div class="separation" />
        <BaseRowWithLabel label="昵称">
          <base-row justify="start">
            <span> {{ this.nickname }} </span>
          </base-row>
          <base-row justify="end">
            <TheButtonChangeNickname :nickname.sync="nickname" />
          </base-row>
        </BaseRowWithLabel>
        <div class="separation" />
        <BaseRowWithLabel label="密码">
          <base-row justify="start">
            <span style="font-size: 20px"> {{ this.fakePassword }} </span>
          </base-row>
          <base-row justify="end">
            <TheButtonChangePassword />
          </base-row>
        </BaseRowWithLabel>
        <div class="separation" />
        <BaseRowWithLabel label="邮箱">
          <base-row justify="start">
            <span> {{ this.email }} </span>
          </base-row>
          <base-row justify="end">
            <TheButtonChangeEmail @updated="handleUpdate" />
          </base-row>
        </BaseRowWithLabel>
      </el-card>
      <div style="height: 40px" />
      <el-card v-loading="loading" class="card">
        <BaseRowWithLabel label="用户名">
          <span> {{ this.username }} </span>
        </BaseRowWithLabel>
        <div class="separation" />
        <BaseRowWithLabel label="用户 ID">
          <span> {{ this.userID }} </span>
        </BaseRowWithLabel>
      </el-card>
    </el-main>
  </el-container>
</template>

<script>
import BaseRowWithLabel from '@/components/BaseRowWithLabel'
import TheAvatarUpload from '@/views/Profile/Main/components/TheAvatarUpload'
import TheButtonChangeNickname from '@/views/Profile/Main/components/TheButtonChangeNickname'
import TheButtonChangePassword from '@/views/Profile/Main/components/TheButtonChangePassword'
import TheButtonChangeEmail from '@/views/Profile/Main/components/TheButtonChangeEmail'
import { dealOtherError } from '@/api/error'
import api from '@/api'

export default {
  components: {
    BaseRowWithLabel,
    TheAvatarUpload,
    TheButtonChangeNickname,
    TheButtonChangePassword,
    TheButtonChangeEmail
  },
  mounted () {
    this.getSelfInfo()
  },
  data () {
    return {
      loading: true,
      userImg: '',
      username: '',
      nickname: '',
      fakePassword: '············',
      userID: '',
      email: ''
    }
  },
  methods: {
    handleUpdateAvatar (link) {
      this.userImg = link
      this.$emit('updated-avatar', link)
    },
    handleUpdate () {
      this.getSelfInfo()
    },
    async getSelfInfo () {
      try {
        const { data: { userInfo } } = await api.getUserSelfInfo()
        this.userImg = userInfo.avatar_link
        this.username = userInfo.username
        this.nickname = userInfo.nickname
        this.userID = userInfo.userID
        this.email = userInfo.email
        setTimeout(() => {
          this.loading = false
        }, 300)
      } catch (error) {
        dealOtherError(this, error)
      }
    }
  }
}
</script>

<style scoped>
.self-info-main {
  height: 100%;
}
.card {
  background-color: white;
  font-size: 0.9em;
}
.separation {
  height: 20px;
}
</style>
