<template>
  <el-container style="height: 100vh">
    <el-header style="padding: 0">
      <BaseHeader back-path="/home" ref="header">
        <template v-slot:left>
          <span> 个人中心 </span>
        </template>
      </BaseHeader>
    </el-header>
    <el-container style="overflow-y: hidden">
      <el-aside width="auto" style="overflow-y: hidden">
        <ProfileAside class="aside" />
      </el-aside>
      <el-main class="main">
        <router-view @updated-avatar="updateAvatar" />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import BaseHeader from '@/components/BaseHeader'
import ProfileAside from '@/views/Profile/ProfileAside'

export default {
  name: 'Profile',
  components: {
    BaseHeader,
    ProfileAside
  },
  methods: {
    updateAvatar (link) {
      this.$refs.header.$refs.avatar.updateAvatar(link)
    }
  }
}
</script>

<style scoped>
.aside {
  box-shadow: rgb(0 0 0 / 6%) 0 1px 1px, rgb(0 0 0 / 10%) 0 2px 4px;
}
.main {
  background-color: rgb(249, 249, 249);
}
</style>
