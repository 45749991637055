<template>
  <el-container class="about-body">
    <el-header style="display: flex">
      <base-row justify="start">
        <span> 关于 </span>
      </base-row>
    </el-header>
    <el-main class="about-wrapper">
      <base-row>
        <BaseLogo />
      </base-row>
      <div class="about-main">
<!--        <el-card>-->
<!--          <el-descriptions title="作者信息">-->
<!--            <el-descriptions-item-->
<!--              v-for="item in items"-->
<!--              :key="item.label"-->
<!--              :label="item.label"-->
<!--            >-->
<!--              {{ item.value }}-->
<!--            </el-descriptions-item>-->
<!--          </el-descriptions>-->
<!--        </el-card>-->
      </div>
    </el-main>
  </el-container>
</template>

<script>
import BaseLogo from '@/components/BaseLogo'

export default {
  components: {
    BaseLogo
  },
  data () {
    return {
      items: [
        {
          label: 'label',
          value: 'value'
        }
      ]
    }
  }
}
</script>

<style scoped>
.about-body {
  height: 100%;
}

.about-wrapper {
  display: flex;
  flex-direction: column;
}

.about-main {
  padding: 20px;
}
</style>
