<template>
  <base-row justify="start">
    <base-row justify="start" class="row-label">
      <span> {{ label }} </span>
    </base-row>
    <slot />
  </base-row>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      require: true
    }
  }
}
</script>

<style scoped>
.row-label {
  width: 60px;
  min-width: 60px;
}
</style>
