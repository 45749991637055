<template>
  <base-button @click="handleClick">修改昵称</base-button>
</template>

<script>
import { dealOtherError } from '@/api/error'
import api from '@/api'

export default {
  props: {
    nickname: String
  },
  methods: {
    async handleClick () {
      try {
        await this.$prompt('昵称', '修改昵称', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputValue: this.nickname,
          inputPattern: /^.{1,20}$/,
          inputErrorMessage: '昵称太短或太长',
          beforeClose: async (action, instance, done) => {
            if (action !== 'confirm') {
              done()
              return
            }
            const nickname = instance.inputValue
            if (nickname === this.nickname) {
              done()
              this.$message.info('昵称未改变')
              return
            }
            instance.showCancelButton = false
            instance.confirmButtonLoading = true
            instance.confirmButtonText = '修改中'
            setTimeout(async () => {
              try {
                await api.changeNickname(nickname)
                this.$emit('update:nickname', nickname)
                setTimeout(() => {
                  done()
                  setTimeout(() => {
                    instance.confirmButtonLoading = false
                    this.$message.success('修改昵称成功！')
                  }, 300)
                }, 300)
              } catch (error) {
                dealOtherError(this, error)
              }
            }, 1000)
          }
        })
      } catch {
        this.$message.info('取消修改昵称')
      }
    }
  }
}
</script>

<style scoped>

</style>
