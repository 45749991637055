<template>
  <el-menu ref="menu" class="menu" :collapse="collapse" :default-active="defaultPath" router>
    <el-menu-item v-for="item in menu" :key="item.path" :index="item.path">
      <div style="position: absolute">
        <i :class="item.icon"></i>
        <span slot="title">{{ item.label }}</span>
      </div>
    </el-menu-item>
  </el-menu>
</template>

<script>
export default {
  props: {
    menu: {
      type: Array,
      require: true
    },
    collapse: {
      type: Boolean,
      default: false
    },
    defaultPath: String
  }
}
</script>

<style scoped>
.menu:not(.el-menu--collapse) {
  width: 180px;
}
.menu {
  background: rgb(244, 244, 245);
  height: 100vh;
}
</style>
